import React, { useState } from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Pluralize from "react-pluralize";
import { renderToString } from 'react-dom/server'

import {userNotification} from "../UserNotification";
library.add(faTrash);

const Remove = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);


    const result = () => {
        return (Pluralize({singular: 'offer', count: 2}).to_string())
    }

    const handleRemove = () => {
        setLoading(true);
        props.bulkRemoveFromRepricer().then(
            (res) => {
            setLoading(false);
            handleClose();
            userNotification(`${res.data.offers.length} ${res.data.offers.length > 1 ? 'offers' : 'offer'} successfully removed from Repricer`, 'success')

        }, (err) => {
            setLoading(false);
            handleClose();
            userNotification('Error removing offers from Repricer', 'danger');
        })
    }

    return (
        <>
        <Button className="text-nowrap" size={'sm'} variant="outline-secondary" onClick={handleShow}>
            <FontAwesomeIcon icon='trash'/> <span className='d-none d-lg-inline'>Remove</span>
        </Button>

            <Modal show={show} onHide={handleClose} centered animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove <Pluralize singular={'offer'} count={props.count} /> from Repricer?   </Modal.Title>
                </Modal.Header>
                <Modal.Body>Offers removed from the Repricer will no longer be frequently checked for Buy Box information. You can re-add offers to the Repricer at any time.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button style={{width:'120px'}} variant="danger" onClick={handleRemove}  >
                        {!loading?
                            'Remove' :
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

// Archiving products will hide them from your sales channels and Shopify admin. You’ll find them using the status filter in your product list.
export default Remove;