import React, {useState} from 'react';
import {Button, Offcanvas, } from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import OfferDetailsBuyBox from "../Repricing/OfferDetailsBuyBox";
library.add(faChevronRight)

const OfferDetails = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="text-nowrap" variant="outline-secondary" onClick={handleShow} style={{padding: '0.15rem 0.5rem'}}>
                <FontAwesomeIcon icon={'chevron-right'} />
            </Button>

            <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                <Offcanvas.Header closeButton style={{'borderBottom': '1px solid #c6cbd1'}}>
                    <Offcanvas.Title>Offer Details</Offcanvas.Title>
                </Offcanvas.Header>

                    <Offcanvas.Body>

                    <h5>{props.offer.title}</h5>
                    <OfferDetailsBuyBox offer={props.offer} updateOffer={props.updateOffer}/>

                    {/*<div className="footer">*/}

                    {/*    /!*<Button variant="secondary" onClick={handleClose}>Done</Button>*!/*/}
                    {/*</div><div className="footer">*/}

                    {/*    /!*<Button variant="secondary" onClick={handleClose}>Done</Button>*!/*/}
                    {/*</div>*/}
                </Offcanvas.Body>
    </Offcanvas>
</>
    );
};

export default OfferDetails;