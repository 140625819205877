import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class Suspended extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {!this.props.sellerAccount.data.sellerAccount.subscriptionRequired && (
          <>
            Your account has been suspended due to non payment. Please contact
            support@shopdeploy.com to arrange.
          </>
        )}
      </div>
    );
  }
}
